import React, { useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import _ from 'lodash';

import FileDetails from '../widgets/list_details';
import ResultTable from '../widgets/result_table';
import PieChart from '../widgets/piechart';
import CircularProgress from '../widgets/circular_progress';
import DownloadModal from '../widgets/download_modal';
import DeliverabilityScoreTable from '../widgets/deliverability_table';

import * as Constants from '../../helpers/constants';
import * as Helpers from '../../helpers/helper'
import { ApiHelper } from '../../helpers/apihelper';

import AppConfigContext from '../dashboard/app_config_context'

const IndividualList = (props) => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const [state, setState] = useState({
    individualList: props.individualList,
    showDownloadModal: false,
    loading: true,
    userDetails: {
      user_id: null,
      user_name: null,
      user_type: null,
      showUserDetailsModel: false
    },
    removeModal: {
      showRemoveModal: false,
      removeMessage: ''
    }
  });

  const openDownloadModal = () => {
    setState(prevState => ({
      ...prevState,
      showDownloadModal: !prevState.showDownloadModal
    }));
  };

  const onVerifyClick = () => {
    
  };

  const downloadInputFile = (list_id) => {
    const apiParams = { list_id };
    let body = _.pick(apiParams, ApiHelper(client, 'DOWNLOAD_EV_LIST_INPUT_FILE').API_BODY)
    const inputFileDownloadWindow = window.open(`/download/${list_id}`);

    ApiHelper(client, 'DOWNLOAD_EV_LIST_INPUT_FILE').API(client, body)
      .then((res) => {
        if (res.status === Constants.SF_API.Success) {
          inputFileDownloadWindow.location = res.data.url;
          inputFileDownloadWindow.focus();
          setTimeout(() => {
            inputFileDownloadWindow.close();
          }, 30000);
        }
      })
      .catch((err) => {
        console.error('getlists api error', err);
      });
  };

  const getResultTable = () => {
    let metrics = null;
    if (state.individualList && state.individualList.last_verified_on) {
      metrics = state.individualList.verification_result;
    } else if (state.individualList && state.individualList.progress) {
      metrics = state.individualList.progress.stats;
    } else {
      metrics = state.individualList.details.analysis_data;
    }
    return (
      <ResultTable
        metrics={metrics}
        resultsArray={Constants.ALL_RESULT_METRICS}
        status={state.individualList.status}
        lastVerifiedOn={state.individualList.last_verified_on}
      />
    );
  };

  const getDeliverabilityScoreTable = () => {
    let metrics = state.individualList && state.individualList.last_verified_on
      ? state.individualList.verification_result
      : state.individualList.details.analysis_data;
      
    return (
      <DeliverabilityScoreTable
        lastVerifiedOn={state.individualList.last_verified_on}
        metrics={metrics}
        resultsArray={Constants.DELIVERABILITY_TABLE_DISPLAY_ORDER}
        individualList={state.individualList}
        openDownloadModal={openDownloadModal}
      />
    );
  };

  const getFileUploadProgress = () => {
    const actionInProgress = state.individualList.action_inprogress;

    if (actionInProgress.action === 'sync' || actionInProgress.action === 'analyse') {
      return (
        <div className='progress-info-div'>
          <div className='file-upload-progress'>
            <p className='w-auto'>{Constants.LIST_ACTIONS[actionInProgress.action].queued}</p>
            <BarLoader
              color='#03a9f4'
              height='3'
            />
          </div>
        </div>
      );
    } else if (actionInProgress.status && actionInProgress.action !== 'export') {
      return <CircularProgress listDetails={state.individualList} />;
    } else {
      return (
        <div className='progress-info-div'>
          <Button
            className='btn-clearout pl-5 px-5'
            onClick={onVerifyClick}
            disabled
            style={{cursor: 'not-allowed', pointerEvents: 'auto'}}
          >
            <img
              className='verify-icon'
              src={require('../../static/img/stats/verify.png')}
              alt='verify'
              style={{width:'20px'}}
            />
            Verify
          </Button>
        </div>
      );
    }
  };

  const getPieChart = () => (
    <div className='chart-data-info'>
      <PieChart
        graphData={state.individualList.verification_result}
        isGraphDataLoading={false}
        height={50}
        width={50}
      />
    </div>
  );

  const getResultDownloadButton = () => {
    const downloadBtnShadow = state.individualList.file_download?.is_downloaded ? 'download-btn-shadow' : '';
    const displayText = state.individualList.file_download?.is_downloaded ? 'Downloaded' : 'Download Result';
    const creditsRequired = state.individualList.file_download?.credits_required || 0;

    return (
      <Button
        className={`mb-1 btn-clearout pl-5 pr-5 btn-clearout-download ${downloadBtnShadow}`}
        onClick={() => openDownloadModal(state.individualList.list_id)}
      >
        <i className='fa fa-download px-2'></i>{displayText}
        {creditsRequired > 0 && <p className='credits-req-span'>({creditsRequired.toLocaleString()} Credits Required)</p>}
      </Button>
    );
  };

  const getUserDetails = (user_id, user_name, user_type) => {
    setState(prevState => ({
      ...prevState,
      userDetails: {
        user_id,
        user_name,
        user_type,
        showUserDetailsModel: true
      }
    }));
  };

  return (
    <li className='list-view'>
      <div className='list-view-container'>
        <div className='file-info'>
          <FileDetails
            createdOn={state.individualList.created_on}
            timeTaken={state.individualList.verification_result?.time_taken}
            listId={state.individualList.list_id}
            mode={state.individualList.mode}
            lastVerifiedOn={state.individualList.last_verified_on}
            lastCancelledOn={state.individualList.last_cancelled_on}
            accountDetails={state.individualList.details}
            listName={state.individualList.details.name}
            fileType={state.individualList.type}
            userDetails={state.individualList.user_details}
            downloadInputFile={downloadInputFile}
            getUserDetails={getUserDetails}
            verifiedOn={state.individualList.verification_started_on}
            action_inprogress={state.individualList.action_inprogress?.status}
            progressUpdatedOn={state.individualList.progress?.updated_on}
            resultExpiresOn={state.individualList.result_expires_on}
            listComponent='email-verifier'
            showUserDetailsModel={state.userDetails.showUserDetailsModel}
            {...props}
          />
          {getDeliverabilityScoreTable()}
          <div className='d-flex flex-column mt-1'>
            {state.individualList.file_download ?
              state.individualList.result_expires_on > 0 ?
                getResultDownloadButton()
                :
                <Button
                  className='btn-clearout btn-clearout-export'
                  disabled
                >
                Result Expired
                </Button>
              : null
            }
            {state.individualList.file_download && state.individualList.result_expires_on > 0 && (
              <p className='file-uploaded-date file-expire-date'>
                Result Expires in {Helpers.getResultExpiresOnTime(state.individualList.result_expires_on)}
              </p>
            )}
          </div>
        </div>
        {getResultTable()}
        {state.individualList.last_verified_on ? getPieChart() : getFileUploadProgress()}
      </div>
      {
        state.showDownloadModal ? (
          <DownloadModal
            showDownloadModal={state.showDownloadModal}
            closeDownloadModal={openDownloadModal}
            user_id={state.user_id}
            listDetails={state.individualList}
            {...props}
          />
        ) : null
      }
    </li>
  );
};

export default IndividualList;
