import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import classnames from 'classnames'
import { useSelector } from 'react-redux';
import { Form, FormControl } from 'react-bootstrap'

import * as Constants from '../../helpers/constants';
import { ApiHelper } from '../../helpers/apihelper'

import IndividualList from './individual_list';
import Filters from './filters';
import TablePagination from '../widgets/pagination';
import LoadingWrapper from '../widgets/loading';

import AppConfigContext from '../dashboard/app_config_context'

import '../../static/css/email_verifier_list_page/ev_lists.css'

const EmailVerifierLists = ({
  ...props
}) => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  let loadLists
  const location = useLocation();
  const [filter, setFilter] = useState({
    selectedPaginationFilter: { display_name: '10', action_value: 10 },
    search: '',
    userEmail: '',
    filters: {
      date_filter: { key: 'created_on', preset: 'ps_last_7_days_including_today' },
      verified: 'in_progress',
    },
    orgId: null,
    currentPageIndex: 0,
  });
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading]=useState(false)
  const abortControllerRef = useRef(null);
  const pollingTimer = useRef(null); // To store the interval timer
  const latestLoadLists = useRef(loadLists);
  const user = useSelector((state) => state.user);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [orgName , setOrgName] = useState(null)

  const getApiParams = useCallback((paramsObject) => {
    const { currentPageIndex, selectedPaginationFilter, search, filters, userEmail } = filter;

    let params = {
      skip: currentPageIndex * selectedPaginationFilter.action_value,
      limit: selectedPaginationFilter.action_value,
      search: search,
      filters: filters,
      sort: {
        created_on: 'desc',
      },
    };

    if (userEmail) {
      params.filters.user_email = userEmail;
    }
    if (paramsObject && paramsObject.user_email) {
      params.filters.user_email =paramsObject.user_email
    }
    if (params.filters?.date_filter && !params.filters.date_filter.preset) {
      delete params.filters.date_filter;
    }
    if (params.filters?.verified === null) {
      delete params.filters.verified;
    }
    if (!params.filters.user_email) {
      delete params.filters.user_email;
    }
    if (paramsObject && paramsObject.org_id) {
      params.filters.org_id = paramsObject.org_id;
    }

    return params;
  }, [filter]);

  const startListPolling = useCallback(() => {
    if (!pollingTimer.current) {
      pollingTimer.current = setInterval(() => {
        latestLoadLists.current(); // Always calls the latest version of loadLists
      }, Constants.POLLING_INTERVAL);
    }
  }, []);

  const stopListPolling = useCallback(() => {
    if (pollingTimer.current) {
      clearInterval(pollingTimer.current);
      pollingTimer.current = null;
    }
  }, []);

  const checkPolling = useCallback((list) => {
    const hasRunningList = _.find(list, (obj) => {
      return obj['action_inprogress'].status === 'running' || obj['action_inprogress'].status === 'queued';
    });

    if (hasRunningList) {
      startListPolling();
    } else {
      stopListPolling();
    }
  }, [startListPolling,stopListPolling]);
  
  loadLists = useCallback((paramsObject) => {
    setIsLoading(true)
    const apiParams = getApiParams(paramsObject);
    let body = _.pick(apiParams, ApiHelper(client, 'EV_LIST').API_BODY)

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    ApiHelper(client, 'EV_LIST').API(client, body, controller.signal)
      .then((res) => {
        setIsLoading(false)
        setLists(res.data.list)
        setTotalPages(Math.ceil(res.data.count / pageSize));
        // Check if polling needs to be started or stopped
        checkPolling(res.data.list);
      })
      .catch((err) => {
        setIsLoading(false)
        console.log('Get Lists API error:', err);
      });
  }, [getApiParams, checkPolling, pageSize, client]);
  
  const filterParams = useCallback((filters) => {
    let newfilters = { ...filter }
    newfilters.filters = filters
    setFilter(newfilters)
  }, [filter, setFilter]);

  const updateFilterFromParams = useCallback((paramsObject) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      userEmail: paramsObject.userEmail || prevFilter.userEmail,
      orgId: paramsObject.orgId || prevFilter.orgId,
    }));
  }, [setFilter]);

  useEffect(() => {
    latestLoadLists.current = loadLists; // Update ref whenever loadLists changes
  }, [loadLists]);

  useEffect(() => {
    let params = new URLSearchParams(location.search)
    let paramsObject = {}
    if (params && params.get('user_email')) {
      paramsObject.userEmail = params.get('user_email')
    }
    if (params && params.get('org_id')) {
      paramsObject.orgId = params.get('org_id')
    }
    if (params && params.get('org_name')) {
      setOrgName(params.get('org_name'))
    }
    updateFilterFromParams(paramsObject);
  },[setOrgName, location.search, updateFilterFromParams])

  useEffect(() => {
    loadLists()

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [loadLists])

  const handlePageSizeChange = (event) => {
    const newPageSize = Number(event.target.value);

    setFilter((prevState) => ({
      ...prevState,
      selectedPaginationFilter: { display_name: newPageSize.toString(), action_value: newPageSize },
    }));
    setPageSize(newPageSize); // Update the pageSize state if you’re using it elsewhere
  };

  const handlePageChange = (newPage) => {
    setFilter((prevState) => ({ ...prevState, currentPageIndex: newPage }));
  };

  const onUserEmailSearch = (event) => {
    let filters = { ...filter }
    filters.userEmail = event.target.value.trim()
    setFilter(filters)
  }

  const onSearch = (event) => {
    let filters = { ...filter }
    filters.search = event.target.value.trim()
    setFilter(filters)
  }

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME

  return (
    <div
      className='d-flex flex-column'
      style={{minHeight: '600px'}}
    >
      <div className='d-flex justify-content-between'>
        <h1 className='m-0 header'>
          {clientDisplayName} Email Verify Lists {orgName ? '/' + orgName : ''}
        </h1>
      </div>
      {isLoading ? (
        <div className="loader-container">
          <LoadingWrapper
            type="bars"
            color="#6229e3"
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <div className='filter-container'>
        <div className="filters-div">
          <Filters
            filterParams={filterParams}
            filter={filter}
            setFilter={setFilter}
            {...props}
          />
          <FormControl
            type="text"
            onChange={onUserEmailSearch}
            placeholder={'Filter by Email address'}
            className={'form-control email-search'}
            value={filter.userEmail}
          />
          <FormControl
            type="search"
            onChange={onSearch}
            placeholder={'Search by list name'}
            className={classnames(filter.search ? 'form-control search-val' : 'form-control search-empty')}
            value={filter.search}
            style={{height: '35px'}}
          />
          <button
            className="btn btn-outline-primary"
            title="Refresh"
            onClick={() => loadLists()}
          >
            <i className="fa fa-refresh"></i>
          </button>
        </div>
      </div>
      <ul className="my-lists">
        {lists.length > 0 ? (
          lists.map((individualList) => (
            <IndividualList
              key={Math.floor(Math.random() * 90000)}
              individualList={individualList}
              user={user}
              list_id={individualList.list_id}
              setFilter={setFilter}
              {...props}
              loadLists={loadLists}
              client={client}
            />
          ))
        ) : (
          <div className="list-container text-center p-4 bg-white">
            <p className="m-0">No Lists Added</p>
          </div>
        )}
      </ul>
      {
        lists.length > 0 ? (
          <div className="d-flex justify-content-between pt-3 pb-1">
            <div style={{paddingLeft: '0.5rem'}}>
              <Form.Select
                value={pageSize}
                onChange={handlePageSizeChange}
                className="form-select"
              >
                {Constants.PAGE_SIZE_OPTIONS.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div>
              <TablePagination
                currentPageIndex={filter.currentPageIndex}
                totalpage={totalPages}
                updateTableData={handlePageChange}
              />
            </div>
          </div>
        ) : null
      }
      <div>
      </div>
    </div>
  )
}

export default EmailVerifierLists