import moment from 'moment'
export const COCRM_USER = 'user'

export const CO_API = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const PAGE_SIZE = {
  values: [50, 100, 150, 200]
}
export const REGEX = {
  EMAIL_SYNTAX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const API_OPTIONS = [
  { value: 'Instant Email Verify', label: 'Instant Email Verify' },
  { value: 'Instant Email Finder', label: 'Instant Email Finder' },
  { value: 'All', label: 'All API\'s' }
];

// export const API_RATELIMIT_SERVICES = {
//   INSTANT_EMAIL_VERIFY_LIMIT: 'instant_email_verify_limit',
//   INSTANT_EMAIL_FINDER_LIMIT: 'instant_email_finder_limit',
// }
export const ACCOUNT_DELETE_REASONS = {
  'data_privacy_concern': 'Data Privacy Concern',
  'alternative_found': 'Alternative Found',
  'temporary_suspension_required': 'Temporary Suspension Required',
  'service_no_longer_needed': 'Service No Longer Needed',
  'none_of_the_above': 'None of the Above'
}

export const SUBSCRIPTION_FILTERS = {
  status: [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'cancelled',
      label: 'Cancelled'
    }
  ],
  planType: [
    {
      value: 'yearly',
      label: 'Annually'
    },
    {
      value: 'monthly',
      label: 'Monthly'
    }
  ],
  userType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'subscriber',
      label: 'Subscribed'
    },
    {
      value: 'paid',
      label: 'Paid'
    }
  ],
  userStatusType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
  ],
  orgUserStatusType: [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
    {
      value: 'new',
      label: 'Yet to Activate'
    },
    {
      value: 'removed',
      label: 'Removed'
    }
  ],
  role: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'owner',
      label: 'Owner'
    },
    {
      value: 'admin',
      label: 'Manager'
    },
    {
      value: 'analyst',
      label: 'Executive'
    },
  ]
}

//linechart
export const RESULTS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'percentage'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'percentage'
  },
  catch_all: {
    display_name: 'Catch All',
    type: 'percentage'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'percentage'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'percentage'
  },
  disposable: {
    display_name: 'Disposable',
    type: 'percentage'
  },
  role_account: {
    display_name: 'Role Account',
    type: 'percentage'
  },
  free_account: {
    display_name: 'Free Account',
    type: 'percentage'
  },
  profile: {
    display_name: 'Profile Account',
    type: 'percentage'
  },
  billable: {
    display_name: 'Billable',
    type: 'value'
  },
  deliverability_score: {
    display_name: 'Deliverability Score',
    type: 'value'
  },
  syntax_error: {
    display_name: 'Syntax Error',
    type: 'percentage'
  },
  billable_credits: {
    display_name: 'Credits Used (Billable)',
    type: 'value'
  },
  safe_to_send: {
    display_name: 'Safe To Send',
    type: 'percentage'
  },
  gibberish: {
    display_name: 'Gibberish Account',
    type: 'percentage'
  }
}

export const SERVICE_BASED_REPORT_FIELDS = {
  verify: {
    total: {
      display_name: 'Total',
      type: 'value'
    },
    valid: {
      display_name: 'Valid',
      type: 'percentage'
    },
    invalid: {
      display_name: 'Invalid',
      type: 'percentage'
    },
    catch_all: {
      display_name: 'Catch All',
      type: 'percentage'
    },
    unknown: {
      display_name: 'Unknown',
      type: 'percentage'
    },
    duplicate: {
      display_name: 'Duplicate',
      type: 'percentage'
    },
    disposable: {
      display_name: 'Disposable',
      type: 'percentage'
    },
    role_account: {
      display_name: 'Role Account',
      type: 'percentage'
    },
    free_account: {
      display_name: 'Free Account',
      type: 'percentage'
    },
    profile: {
      display_name: 'Profile Account',
      type: 'percentage'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    },
    deliverability_score: {
      display_name: 'Deliverability Score',
      type: 'value'
    },
    syntax_error: {
      display_name: 'Syntax Error',
      type: 'percentage'
    },
    billable_credits: {
      display_name: 'Credits Used (Billable)',
      type: 'value'
    },
    safe_to_send: {
      display_name: 'Safe To Send',
      type: 'percentage'
    }
  },
  finder: {
    total: {
      display_name: 'Total Request',
      type: 'value'
    },
    found: {
      display_name: 'Email Found',
      type: 'value'
    },
    not_found: {
      display_name: 'Email Not Found',
      type: 'value'
    },
    business: {
      display_name: 'Business',
      type: 'value'
    },
    role: {
      display_name: 'Role',
      type: 'value'
    },
    confidence_score: {
      display_name: 'Confidence Score',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  prospect: {
    total_billable_email_contact: {
      display_name: 'Email Contacts',
      type: 'value'
    },
    total_billable_phone_contact: {
      display_name: 'Phone Contacts',
      type: 'value'
    },
    total_billable_lead: {
      display_name: 'Total Prospects',
      type: 'value'
    },
    total_credits_charged: {
      display_name: 'Total Credits',
      type: 'value'
    },
    email: {
      display_name: 'Email Lead',
      type: 'value'
    },
    phone: {
      display_name: 'Phone Lead',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  overall: {
    ev_billable: {
      display_name: 'Email Verifier',
      type: 'value'
    },
    ef_billable: {
      display_name: 'Email Finder',
      type: 'value'
    },
    prospect_billable: {
      display_name: 'Prospect',
      type: 'value'
    }
  }
}
export const GRAPH_COLORS = ['#c6e377', '#f16f6f', '#75cac3', '#c0c0c0', '#970690']

export const GRAPH_AREA_COLORS = ['rgba(199, 227, 120, 0.20)', 'rgba(241, 111, 111, 0.20)', 'rgba(118, 203, 196, 0.20)', 'rgba(191, 191, 191, 0.20)', 'rgba(235, 193, 233, 0.2)']
/////////////////////
export const DATE_RANGE_PRESETS = {
  'Today': 'ps_today',
  'Yesterday': 'ps_yesterday',
  'This week (Mon - Today)': 'ps_this_week_mon_today',
  'Last 7 days (Including Today)': 'ps_last_7_days_including_today',
  'Last week (Mon - Sun)': 'ps_last_week_mon_sun',
  'This Month': 'ps_this_month',
  'Last Month': 'ps_last_month',
  'This Year': 'ps_this_year',
  'Last Year': 'ps_last_year'
};

export const RANGES = {
  'All': [moment(), moment()],
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This week (Mon - Today)': [moment().startOf('isoweek'), moment()],
  'Last 7 days (Including Today)': [moment().subtract(6, 'days'), moment()],
  'Last week (Mon - Sun)': [moment().subtract(1, 'week').startOf('isoweek'), moment().subtract(1, 'week').endOf('isoweek')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment()],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
}

export const DATERANGE_PRESETS = {
  date_range_presets: [
    'ps_today',
    'ps_yesterday',
    'ps_this_week_mon_today',
    'ps_last_7_days_including_today',
    'ps_last_week_mon_sun',
    'ps_this_month',
    'ps_last_month',
    'ps_this_year',
    'ps_last_year'
  ],
  date_range_presets_definitions: {
    ps_today: {
      display_name: 'Today',
      value: 'ps_today'
    },
    ps_yesterday: {
      display_name: 'Yesterday',
      value: 'ps_yesterday'
    },
    ps_last_7_days_including_today: {
      display_name: 'Last 7 days (Including Today)',
      value: 'ps_last_7_days_including_today'
    },
    ps_this_week_mon_today: {
      display_name: 'This week (Mon - Today)',
      value: 'ps_this_week_mon_today'
    },
    ps_last_week_mon_sun: {
      display_name: 'Last week (Mon - Sun)',
      value: 'ps_last_week_mon_sun'
    },
    ps_this_month: {
      display_name: 'This Month',
      value: 'ps_this_month'
    },
    ps_last_month: {
      display_name: 'Last Month',
      value: 'ps_last_month'
    },
    ps_this_year: {
      display_name: 'This Year',
      value: 'ps_this_year'
    },
    ps_last_year: {
      display_name: 'Last Year',
      value: 'ps_last_year'
    },
    ps_last_24_hours: {
      display_name: 'Last 24 Hrs',
      value: 'ps_last_24_hours'
    }
  }
}

export const CLIENT_SECRET = '5b98682fe6339bf7706a3bf335b1568089d8a507c9a6a95ef60a3739b862a961'

export const CLIENTS = {
  CLEAROUT: {
    NAME: 'clearout',
    SHORT_NAME: 'co',
    DISPLAY_NAME: 'Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Users'
  },
  CLEAROUTPHONE: {
    NAME: 'clearoutphone',
    SHORT_NAME: 'cop',
    DISPLAY_NAME: 'Clearoutphone',
    SERVICE: 'clearoutphone',
    DOWNLOAD_NAME: 'Download ClearoutPhone Users'
  },
  SPOKESLY_CLEAROUT: {
    NAME: 'spokesly_clearout',
    SHORT_NAME: 'spokesly',
    DISPLAY_NAME: 'Spokesly Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Spokesly Users'
  },
  EU_CLEAROUT: {
    NAME: 'eu_clearout',
    SHORT_NAME: 'co_eu',
    DISPLAY_NAME: 'EU Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout EU Users'
  },
  EP_CLEAROUT: {
    NAME: 'ep_clearout',
    SHORT_NAME: 'co_ep',
    DISPLAY_NAME: 'EP Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout EP Users'
  },
  PEPIPOST_CLEAROUT: {
    NAME: 'pepipost_clearout',
    SHORT_NAME: 'pepipost',
    DISPLAY_NAME: 'Pepipost Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Pepipost Users'
  },
  CAMS_CLEAROUT: {
    NAME: 'cams_clearout',
    SHORT_NAME: 'co_cams',
    DISPLAY_NAME: 'Cams Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Cams Users'
  },
  CLEAROUTS: {
    NAME: 'clearouts',
    SHORT_NAME: 'co',
    DISPLAY_NAME: 'Clearout',
    SERVICE: 'clearout',
    DOWNLOAD_NAME: 'Download Clearout Users'
  },
}

export const API_RATE_LIMIT_OPTIONS = {
  [CLIENTS.CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.CLEAROUTPHONE.NAME]: [
    { value: 'instant_phonenumber_validate_limit', label: 'Instant Phone Number Validator' },
    // { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.EU_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.EP_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
  [CLIENTS.CAMS_CLEAROUT.NAME]: [
    { value: 'instant_email_verify_limit', label: 'Instant Email Verify' },
    { value: 'instant_email_finder_limit', label: 'Instant Email Finder' },
    { value: 'all', label: 'All API\'s' }
  ],
}



export const BREAD_CRUMB_OPTIONS = {
  [CLIENTS.CLEAROUT.NAME]: [
    { label: 'Clearout Users', href: '/clearout/users', active: false },
    { label: 'Clearout Individual User Info', href: '/clearout/individual_user_info', active: true },
  ],
  [CLIENTS.CLEAROUTPHONE.NAME]: [
    { label: 'ClearoutPhone Users', href: '/clearoutphone/users', active: false },
    { label: 'ClearoutPhone Individual User Info', href: '/clearoutphone/individual_user_info', active: true },
  ],
  [CLIENTS.SPOKESLY_CLEAROUT.NAME]: [
    { label: 'Spokesly Clearout Users', href: '/spokesly_clearout/users', active: false },
    { label: 'Spokesly Clearout Individual User Info', href: '/spokesly_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.EU_CLEAROUT.NAME]: [
    { label: 'Clearout EU Users', href: '/eu_clearout/users', active: false },
    { label: 'Clearout EU Individual User Info', href: '/eu_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.EP_CLEAROUT.NAME]: [
    { label: 'Clearout EP Users', href: '/ep_clearout/users', active: false },
    { label: 'Clearout EP Individual User Info', href: '/ep_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.PEPIPOST_CLEAROUT.NAME]: [
    { label: 'Pepipost Clearout Users', href: '/pepipost_clearout/users', active: false },
    { label: 'Pepipost Clearout Individual User Info', href: '/pepipost_clearout/individual_user_info', active: true },
  ],
  [CLIENTS.CAMS_CLEAROUT.NAME]: [
    { label: 'Cams Clearout Users', href: '/cams_clearout/users', active: false },
    { label: 'Cams Clearout Individual User Info', href: '/cams_clearout/individual_user_info', active: true },
  ],
  clearout_org: [
    { label: 'Clearout Organization Users', href: '/clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/clearout/organization_info', active: true },
  ],
  spokesly_clearout_org: [
    { label: 'Clearout Organization Users', href: '/spokesly_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/spokesly_clearout/organization_info', active: true },
  ],
  eu_clearout_org: [
    { label: 'Clearout Organization Users', href: '/eu_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/eu_clearout/organization_info', active: true },
  ],
  ep_clearout_org: [
    { label: 'Clearout Organization Users', href: '/ep_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/ep_clearout/organization_info', active: true },
  ],
  pepipost_clearout_org: [
    { label: 'Clearout Organization Users', href: '/pepipost_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/pepipost_clearout/organization_info', active: true },
  ],
  cams_clearout_org: [
    { label: 'Clearout Organization Users', href: '/cams_clearout/org_list', active: false },
    { label: 'Clearout Organization Info', href: '/cams_clearout/organization_info', active: true },
  ],
};

export const CURRENCY = {
  USD: {
    flag: require('../../src/static/svg/us_flag.svg'),
    symbol: '$',
    name: 'USD',
    locale: 'en-US'
  },
  INR: {
    flag: require('../../src/static/svg/in_flag.svg'),
    symbol: '₹',
    name: 'INR',
    locale: 'en-IN'
  }
}

export const DISPLAY_USER_ROLES = {
  admin: {
    value: 'admin',
    display_name: 'Manager',
    profile_tabs: ['credits_limits', 'analytics']
  },
  analyst: {
    value: 'analyst',
    display_name: 'Executive',
    profile_tabs: ['credits_limits', 'analytics']
  },
  owner: {
    value: 'owner',
    display_name: 'Owner',
    profile_tabs: ['credits_limits', 'plans', 'subscription_history', 'invoices_receipts', 'analytics']
  },

}

export const COUPON_TYPE = {
  standard: {
    display: 'Standard',
    value: 'standard'
  },
  discount: {
    display: 'Discount',
    value: 'discount'
  },
  bonus: {
    display: 'Bonus',
    value: 'bonus'
  }
};

export const COUPON_MIN_VALUE_CREDITS = 5000;
export const COUPON_MAX_VALUE_CREDITS = 10000;
export const COUPON_CREDITS_DEFAULT_VALUE = 100000;
export const COUPON_MAX_VALUE_FIXED = 10000;
export const COUPON_REDEEM_COUNT = 100000;
export const COUPON_MIN_FOR_FIXED = 21;
export const COUPON_SLAB_UNIT_PRICE = 0.001;
export const COUPON_SLAB_MAX_UNIT_PRICE = 0.005;
export const COUPON_DISCOUNT_FIXED_DEFAULT = 10;
export const COUPON_MAX_LENGTH = 15;
export const COUPON_MIN_LENGTH = 4;
export const MIN_VAL = 3000;
export const MAX_VAL = 5000000;
export const DURATION_VALUE = 1;


export const PAYMENT_ACCOUNTS = {
  PAYPAL: [
    { label: 'PayPal IND', value: 'paypal::india' },
    { label: 'PayPal US', value: 'paypal::us' },
  ],
  STRIPE: [
    { label: 'Stripe IND', value: 'stripe::india' },
    { label: 'Stripe US', value: 'stripe::us' },
  ],
}


export const COUPON_CONSTANTS = {
  NAME: 'Coupon name',
  COUPON_ID: 'Coupon id will used by customers for applying discount',
  REDEEM_COUNT: 'Number of times the customer can redeem the coupon',
  COUPON_TYPE: 'Coupon type : standard or discount or bonus coupon',
  DISCOUNT_TYPE: 'Select discount type',
  SLAB_MIN: 'Minimum credits to be purchased by user for applying the coupon',
  SLAB_MAX: 'Maximum credits to be purchased by user for applying the coupon',
  SLAB_VAL: 'Unit price for each credit',
  FIXED_MIN: 'Minimum billing amount for coupon to be applied',
  FIXED_MAX: 'Maximum billing amount for coupon to be applied',
  FIXED_VAL: 'Flat value which will be subtracted from the billing price',
  PERC_MIN: 'Minimum billing amount for the coupon to be applied',
  PERC_MAX: 'Maximum billing amount for the coupon to be applied',
  PERC_VAL: 'Percentage of amount to be decreased from billing price',
  CREDITS: 'Credits for assigning the user',
  USER_EMAIL: 'Select the user for this coupon',
  AFFILIATE: 'Enter the affiliate name',
  DAILY_VERIFY_LIMIT: 'Enter the daily limit for verification',
  DURATION_IN_MONTH: 'Number of months credits to be assigned to the user',
};


export const COUPONS_DROPDOWNS = {
  CLEAROUT: {
    discount: 'Discount',
    standard: 'Standard',
    bonus: 'Bonus'
  },
  CLEAROUTPHONE: {
    discount: 'Discount',
    standard: 'Standard',
  },
}
export const ACTION_TYPES = {
  domain_blocked: 'Domain Blocked',
  domain_unblocked: 'Domain Unblocked',
  email_denied: 'Email Denied',
  email_whitelisted: 'Email Whitelisted',
  email_delisted: 'Email Delisted',
  ip_blocked: 'IP Blocked',
  ip_unblocked: 'IP Unblocked',
  country_blocked: 'Country Blocked',
  matomo_blocked: 'Matomo Blocked',
  captcha_blocked: 'Captcha Blocked'
}

export const ACTIONS = {
  signup_abuse: 'Signup Abuse',
  signup_activation_abuse: 'Signup Activation Abuse',
  email_edit_abuse: 'Email Edit Abuse'
}

export const RENEWAL_UNITS = {
  month: {
    short_name: 'mo',
    name: 'Monthly',
    patterns: [
      {
        unit: 'month',
        count: 1
      },
      {
        unit: 'day',
        count: 30
      }
    ]
  },
  year: {
    short_name: 'yr',
    name: 'Yearly',
    patterns: [
      {
        unit: 'year',
        count: 1
      },
      {
        unit: 'day',
        count: 360
      },
      {
        unit: 'day',
        count: 365
      }
    ]
  },
  day: {
    short_name: 'day',
    name: 'Daily',
    patterns: [
      {
        unit: 'day',
        count: 1
      }
    ]
  },
  week: {
    short_name: 'week',
    name: 'Weekly',
    patterns: [
      {
        unit: 'week',
        count: 1
      }
    ]
  }
}

export const PAGINATION_FILTERS = {
  '10': {
    display_name: '10',
    action_value: 10
  },
  '25': {
    display_name: '25',
    action_value: 25
  },
  '50': {
    display_name: '50',
    action_value: 50
  },
  '100': {
    display_name: '100',
    action_value: 100
  }
};

export const SF_API = {
  Success: 'success',
  Failure: 'failed',
  Header: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

export const ALL_RESULT_METRICS = [
  'total',
  'valid',
  'invalid',
  'catch_all',
  'unknown',
  'duplicate',
  'syntax_error',
  'disposable',
  'gibberish',
  'free_account',
  'role_account'
];

export const DELIVERABILITY_TABLE_DISPLAY_ORDER = ['total', 'billable'];

export const LIST_ACTIONS = {
  sync: {
    value: 'sync',
    display: 'Sync',
    progress: 'Syncing',
    queued: ' Queued',
    history: 'synced'
  },
  verify: {
    value: 'verify',
    display: 'Verify',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  pnv: {
    value: 'pnv',
    display: 'PNV',
    progress: 'Verifying',
    queued: ' Queued',
    history: 'verified'
  },
  analyse: {
    value: 'analyse',
    display: 'Analyse',
    progress: 'Analyzing',
    queued: ' Queued',
    history: 'analysed'
  },
  export: {
    value: 'export',
    display: 'Export',
    progress: 'Exporting',
    queued: ' Queued',
    history: 'exported'
  },
  failed: {
    value: 'failed',
    display: 'Failed',
    progress: 'Failing',
    queued: ' Queued',
    history: 'failed'
  },
  find_emails: {
    value: 'email_finder',
    display: 'Finding Emails',
    progress: 'Finding Emails',
    queued: ' Queued',
    history: 'Email Found'
  },
  enrich: {
    progress: 'Enriching !!!'
  }
};

export const LIST_TYPES = {
  mailchimp: {
    value: 'mailchimp',
    label: 'Mailchimp'
  },
  apollo: {
    value: 'apollo',
    label: 'Apollo'
  },
  active_campaign: {
    value: 'active_campaign',
    label: 'ActiveCampaign'
  },
  moosend: {
    value: 'moosend',
    label: 'Moosend'
  },
  mailerlite: {
    value: 'mailerlite',
    label: 'MailerLite'
  },
  sendgrid: {
    value: 'sendgrid',
    label: 'Sendgrid'
  },
  automizy: {
    value: 'automizy',
    label: 'Automizy'
  },
  clevertap: {
    value: 'clevertap',
    label: 'CleverTap'
  },
  hubspot: {
    value: 'hubspot',
    label: 'Hubspot'
  },
  lemlist: {
    value: 'lemlist',
    label: 'Lemlist'
  },
  go_high_level: {
    value: 'go_high_level',
    label: 'GoHighLevel'
  },
  upload: {
    value: 'upload',
    label: 'Upload'
  },
  leads: {
    value: 'leads',
    label: 'Leads'
  },
  all: {
    label: 'All',
    value: 'all'
  }
};

export const VERIFIED_TYPES = {
  verified: {
    value: 'verified',
    label: 'Verified'
  },
  non_verified: {
    value: 'non_verified',
    label: 'Non-Verified'
  },
  in_progress: {
    value: 'in_progress',
    label: 'In Progress'
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled'
  }
};

export const PIECHART_RESULT_ORDER = [
  'valid',
  'invalid',
  'catch_all',
  'unknown'
];

export const LIST_STATUSES = {
  SUCCESS: 'success',
  FAILED: 'failed',
  CANCELLED: 'cancelled'
}

export const ANALYSIS_TABLE_DISPLAY_ORDER = [
  'duplicate',
  'syntax_error',
  'disposable',
  'gibberish',
  'free_account',
  'role_account',
  'profile'
];

export const TOP_RESULTS_TABLE_DISPLAY_ORDER = [
  'total',
  'valid',
  'invalid',
  'catch_all',
  'unknown'
];

export const EMAIL_VERIFY_MODES = {
  'highest_accuracy': 'Highest Accuracy',
  'fastest_turnaround': 'Fastest Turnaround',
}

export const INTEGRATION_PLATFORMS = ['apollo', 'mailchimp', 'active_campaign', 'moosend', 'hubspot', 'mailerlite', 'sendgrid', 'clevertap', 'lemlist', 'go_high_level']

export const EMAIL_FINDER_DELIVERABILITY_TABLE_DISPLAY_ORDER = ['total', 'billable', 'confidence_level'];

export const DOWNLOAD_VALIDATION_FILTER = [
  'valid',
  'invalid',
  'catch_all',
  'unknown'
];

export const DELIVERABLES_GUARANTEED =
  'The result will only include the email addresses that are guaranteed to be delivered to the mailbox of the recipient, i.e. no bounce back. Sending emails to these email addresses is completely safe as long as the email is sent anytime before 24 hours from the verified time. The downloaded result will also contain Clearout standard columns appended to the original columns.';

export const DELIVERABLES =
  'The result file will include only the valid email addresses appended to your original list. Sending emails to these email addresses are completely safe.';

export const NON_DELIVERABLES_SAFE_TO_SEND =
  'The result will include the email addresses that will certainly bounce, so it is highly recommended not to send any emails to such addresses and unsubscribe them from the mailing list';

export const DELIVERABLES_WITH_RISK =
  'The result file will include email addresses that are guaranteed to be delivered along with the email addresses that are determined as risky. The email address of this result file are safe to be used when bounce rate is strictly below 5% or running dedicated mail server with out dependence on external Email Service Provider(ESP).';

export const VALID_PHONE_NUMBER =
  'The resulting file will include only the valid phone numbers appended to your original list. These numbers are syntax free and reachable.';

export const INVALID_PHONE_NUMBER =
  'The resulting file will include only the Invalid phone numbers appended to your original list. These numbers won\'t be reachable and can be safely removed from the list.';
export const VALIDATION_FILTERS = {
  valid: {
    value: 'valid',
    name: 'Valid'
  },
  invalid: {
    value: 'invalid',
    name: 'Invalid'
  },
  catch_all: {
    value: 'catch_all',
    name: 'Catch All'
  },
  unknown: {
    value: 'unknown',
    name: 'Unknown'
  }
};

export const NON_DELIVERABLES =
  'The result file will include only the invalid email addresses appended to your original list. An email address will be marked \'invalid\' for many reasons. Avoid sending emails to these email addresses.';

export const POLLING_INTERVAL = 15000; // 15 seconds 

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
];
