import React, { useState, useEffect, useCallback, useRef,useContext } from 'react';
import _ from 'lodash';
import { FormControl } from 'react-bootstrap';

import UserListTable from '../tables/user_list/user_list_table';
import * as Constants from '../../helpers/constants';
import { ApiHelper } from '../../helpers/apihelper';
import tableFormatters from '../tables/user_list/helpers/user_list_formatter'
import * as UserListTableConstnats from '../tables/user_list/helpers/constant'
import AppConfigContext from '../dashboard/app_config_context';


const initialState = {
  data: [],
  isLoading: false,
  searchText: '',
  dataToDownload: '',
  filename: '',
  tableDataLength: 0,
  sorting: [],
  filters: {
    date_range: {
      filter_label: 'date_range',
      value: {
        key: 'created_on',
        preset: 'ps_last_7_days_including_today'
      }
    },
    status: {
      filter_label: 'User Status',
      label: 'All',
      value: 'all'
    }
  },
};

const filterParams = (filters) => {
  let queryFilter = {
    subs: filters.subs?.value || null,
    subs_status: filters.subs_status?.value || null,
    subs_plan_type: filters.subs_plan_type?.value || null,
    status: filters.status?.value || null,
    payment_status: null,
    date_range: filters.date_range?.value || null,
    org_id: filters.org_filter?.value || null,
  };

  if (queryFilter.subs === 'paid') {
    queryFilter.payment_status = 'paid';
    queryFilter.subs = null;
  }

  if (queryFilter.status === 'all') {
    queryFilter.status = null;
  }
  return queryFilter;
};

const OrgListPage = () => {
  const [state, setState] = useState(initialState);
  const csvLinkRef = useRef(null);
  const abortControllerRef = useRef(null); // Ref to hold the abort controller

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getUsersListApi = useCallback((requestBody) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    let body = _.pick(requestBody, ApiHelper(client, 'ORG_LIST').API_BODY)
    setState((prevState) => ({ ...prevState, isLoading: true }));

    // Pass the `signal` to the API call
    ApiHelper(client, 'ORG_LIST').API(client, body, abortControllerRef.current.signal)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            data: response.data.data,
            tableDataLength: Math.ceil(response.data.count / body.limit),
          }));
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error(error);
        }
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      });
  }, [client]);


  useEffect(() => {
    const sort = Array.isArray(state.sorting) && state.sorting.length > 0
      ? { [state.sorting[0].id]: state.sorting[0].desc ? 'desc' : 'asc' }
      : { created_on: 'desc' };

    const body = {
      skip: 0,
      limit: 50,
      search: state.searchText,
      sort,
      filters: { ...filterParams(state.filters) },
      client_secret: Constants.CLIENT_SECRET,
      client,
    };

    getUsersListApi(body);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [getUsersListApi, state.filters, state.searchText, state.sorting, client,]);

  useEffect(() => {
    if (csvLinkRef.current && state.dataToDownload) {
      csvLinkRef.current.link.click();
    }
  }, [state.dataToDownload, csvLinkRef]);

  const getClearoutUsers = (skip, limit = 50, searchText, filters, sorting) => {
    const sortingData = sorting && sorting.length > 0
      ? { [sorting[0].id]: sorting[0].desc ? 'desc' : 'asc' }
      : { created_on: 'desc' };

    const body = {
      skip: skip || 0,
      limit: limit || 50,
      search: searchText || state.searchText,
      sort: sortingData,
      filters: { ...filterParams(filters || state.filters) },
      client_secret: Constants.CLIENT_SECRET,
      client,
    };

    getUsersListApi(body);
  };

  const clearSearch = () => {
    setState((prevState) => ({ ...prevState, searchText: '' }));
  };

  const onSearch = (event) => {
    setState((prevState) => ({ ...prevState, searchText: event.target.value }));
  };

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME;

  const columns = UserListTableConstnats.ORG_LIST_TABLE_COLUMNS[client].map(col => {
    return {
      header: col.header,
      accessorKey: col.accessorKey,
      cell: (cell) => tableFormatters[col.cellFormatter](cell, client),
    }
  })

  return (
    <div className='overflow-auto'>
      <div className='d-flex justify-content-between'>
        <h1 className='m-0 header'>
          {clientDisplayName} Organization list
        </h1>
      </div>
      <div className='d-flex justify-content-end py-2'>
        <div className='search-container'>
          <FormControl
            type="text"
            placeholder="Search..."
            onChange={onSearch}
            value={state.searchText}
          />
          {state.searchText && (
            <button
              className='clear-button'
              onClick={clearSearch}
            >
              x
            </button>
          )}
        </div>
      </div>
      <div>
        <UserListTable
          columns={columns}
          getClearoutUsers={getClearoutUsers}
          data={state.data}
          isLoading={state.isLoading}
          sorting={state.sorting}
          filterParams={filterParams}
          searchText={state.searchText}
          filters={state.filters}
          tableDataLength={state.tableDataLength}
        />
      </div>
    </div>
  );
};

export default OrgListPage;
